//引入路由
import { createRouter, createWebHashHistory } from "vue-router";
//定义路由
const routes = [
    {
        path: '/',
        component: () => import('../page/home.vue')
    },
    {
        path: '/product',
        component: () => import('../page/product.vue')
    },
    {
        path: '/about',
        component: () => import('../page/about.vue')
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//导出路由
export default router
<template>
  <el-scrollbar height="100%">
    <div class="topHeader">
      <div class="conter">
        <div style="display: flex; align-items: center">
          <img src="./image/logo.png
          " alt="" />
          <span>Q M F</span>
        </div>

        <ul class="top">
          <li v-for="(val, i) in navList" :key="i" :class="activePath == val.path ? 'active' : ''" @click="to(val)">
            {{ val.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="box">
      <RouterView />
    </div>

    <div class="bom">
      <div class="bomTitle">
        <div class="bomTitleList">
          <ul>
            <li>关于我们</li>
            <li @click="goMf('公司介绍')">公司介绍</li>
            <li>技术资质</li>
            <li @click="goMf('软件下载')">软件下载</li>
          </ul>
          <ul>
            <li>支持服务</li>
            <li @click="goMf('魔方学院')">魔方学院</li>
            <li @click="goMf('指标商城')">指标商城</li>
            <li @click="goMf('策略超市')">策略超市</li>
            <li @click="goMf('名师专栏')">名师专栏</li>
            <li @click="goMf('量化培训')">量化培训</li>
            <li @click="goMf('量化定制')">量化定制</li>
          </ul>
          <ul>
            <li>诚邀合作</li>
            <li @click="goMf('联系我们')">联系我们</li>
            <li @click="goMf('加入我们')">加入我们</li>
          </ul>
          <ul>
            <li>友情链接</li>
            <li @click="goMf('聚客量化')">聚客量化</li>
          </ul>
        </div>

        <div class="bomTitleRight">
          <p>关注或联系我们</p>
          <div>
            <img src="./image/gzh.png" alt="" />
            <br />
            <span>官方公众号</span>
          </div>
          <div>
            <img src="./image/kfh.png" alt="" />
            <br />
            <span>官方客服号</span>
          </div>
        </div>
      </div>
      <div class="ba">
        <span style="cursor: pointer" @click="goMf('蜀')">蜀ICP备2021033033号-2</span>&emsp;
        <span style="cursor: pointer" @click="goMf('备')">
          <img src="./image/jh.png" alt="" style="display: inline-block; width: 18px; height: 18px" />
          川公网安备51015602000652号</span>&emsp; ©2023 All Rights Reserved
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  data() {
    return {
      navList: [
        { name: "首页", path: "/" },
        { name: "下载中心", path: "/product" },        
        { name: "使用文档", path: "https://www.qmfquant.com/app/index.html" },
        { name: "策略编写", path: "https://qmfquant.com/static/doc/code/strategyEdit.html" },
        { name: "指标编写", path: "https://qmfquant.com/static/doc/code/indicatorEdit.html" },
        { name: "魔方学院", path: "https://academy.qmfquant.com/" },
        { name: "关于我们", path: "/about" },
      ],
      activePath: this.$route.path,
      openListTitle: ["使用文档", "策略编写","指标编写","魔方学院"]
    };
  },
  watch: {
    $route(to) {
      for (const item of this.navList) {
        if (item.path === to.path) {
          this.activePath = to.path;
          return;
        }
      }
    },
  },
  methods: {
    to(val) {
      if (this.openListTitle.includes(val.name)) {
        window.open(val.path)
      } else {
        this.activePath = val.path;
        this.$router.push({
          path: val.path,
        });
      }
    },
    goMf(val) {
      console.log(val);
      switch (val) {
        case "魔方学院":
          window.open("https://academy.qmfquant.com/");
          break;
        case "指标商城":
          window.open("https://academy.qmfquant.com/archives/category/clzl/tszb");
          break;
        case "策略超市":
          window.open("https://academy.qmfquant.com/archives/category/clzl/lhmx");
          break;
        case "名师专栏":
          window.open("https://academy.qmfquant.com/archives/category/mszl");
          break;
        case "量化培训":
          window.open("https://academy.qmfquant.com/archives/category/lhpx");
          break;
        case "量化定制":
          window.open("https://academy.qmfquant.com/archives/category/zsdz");
          break;
        case "聚客量化":
          window.open("https://www.jukequant.com/home");
          break;
        case "蜀":
          window.open("https://beian.miit.gov.cn/#/Integrated/index");
          break;
        case "备":
          window.open("https://www.beian.gov.cn/portal/registerSystemInfo");
          break;
        case "公司介绍":
          this.$router.push({ path: "/about" });
          break;
        case "软件下载":
          this.$router.push({ path: "/product" });
          break;
        case "联系我们":
          this.$router.push({ path: "/about" });
          break;
        case "加入我们":
          window.open(
            "https://www.zhipin.com/gongsi/b83a19dd5da890781nx82ty4FVY~.html?ka=company-intro"
          );
          break;
      }
    },
  },
});
</script>
<style scoped>
@media (min-width: 1200px) {

  /* 在大于等于 1025px 的屏幕宽度下应用的样式 */
  .topHeader {
    position: fixed;
    background-color: #2c3e50;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 99;
  }

  .box {
    margin-top: 60px;
    height: calc(100% - 345px);
  }

  .conter {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .conter span {
    font-size: 14px;
    color: azure;
  }

  .topHeader img {
    width: 50px;
  }

  .topHeader ul {
    display: flex;
    list-style: none;
    height: 100%;
    align-items: center;
  }

  .topHeader ul li {
    width: 128px;
    color: #f1f1f1;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }

  .topHeader ul li.active {
    color: #4da2ff;
  }

  .bom {
    height: 285px;
    width: 100%;
    padding-top: 20px;
    background: #2c3e50;
    margin-top: 20px;
  }

  .bom .bomTitle {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 15px 0;
    justify-content: space-between;
  }

  .bom .bomTitle .bomTitleList {
    width: 70%;
    display: flex;
    justify-content: space-around;
  }

  .bom .bomTitle .bomTitleList ul {
    list-style: none;
    color: #909399;
    font-size: 14px;
    font-weight: bold;
  }

  .bom .bomTitle .bomTitleList ul li:nth-child(1) {
    color: #fff;
    font-size: 16px;
    margin: 0 0 10px;
  }

  .bom .bomTitle .bomTitleList ul li {
    margin: 10px 0;
    cursor: pointer;
  }

  .bom .bomTitle .bomTitleRight {
    width: 320px;
    font-size: 14px;
    font-weight: bold;
    color: #909399;
  }

  .bom .bomTitle .bomTitleRight p {
    margin-bottom: 10px;
    text-indent: 20px;
  }

  .bom .bomTitle .bomTitleRight div {
    display: inline-block;
    width: 50%;
    text-align: center;
  }

  .bom .bomTitle .bomTitleRight div:nth-child(1) {
    margin-right: 20px;
  }

  .bom .bomTitle .bomTitleRight img {
    width: 120px;
    height: 120px;
  }

  .bom .ba {
    border-top: 1px solid #646262;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 20px;
  }

  .bom .ba img {
    width: 230px;
    height: 226px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .topHeader {
    position: fixed;
    background-color: #2c3e50;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4.5vh;
    z-index: 99;
  }

  .box {
    margin-top: 4.5vh;
    height: calc(100% - 4.5vh);
  }

  .conter {
    width: 98vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .conter span {
    font-size: 0.1rem;
    color: azure;
  }

  .topHeader img {
    width: 3rem;
  }

  .topHeader ul {
    display: flex;
    list-style: none;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    flex: 1;
  }

  .topHeader ul li {
    width: 8rem;
    color: #f1f1f1;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
  }

  .topHeader ul li.active {
    color: #4da2ff;
  }

  .bom {
    width: 100%;
    padding-top: 20px;
    background: #2c3e50;
    margin-top: 20px;
  }

  .bom .bomTitle {
    display: flex;
    width: 98vw;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 1rem 0;
    justify-content: space-between;
  }

  .bom .bomTitle .bomTitleList {
    width: 60%;
    display: flex;
    justify-content: space-around;
  }

  .bom .bomTitle .bomTitleList ul {
    list-style: none;
    color: #909399;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .bom .bomTitle .bomTitleList ul li:nth-child(1) {
    font-size: 1rem;
    color: #fff;
    margin: 0 0 1rem;
  }

  .bom .bomTitle .bomTitleList ul li {
    margin: 0.5rem 0;
    cursor: pointer;
  }

  .bom .bomTitle .bomTitleRight {
    width: 50%;
    font-size: 0.9rem;
    font-weight: bold;
    color: #909399;
  }

  .bom .bomTitle .bomTitleRight p {
    margin-bottom: 10px;
    text-indent: 1rem;
  }

  .bom .bomTitle .bomTitleRight div {
    display: inline-block;
    width: 50%;
    text-align: center;
  }

  .bom .bomTitle .bomTitleRight div:nth-child(1) {
    margin-right: 20px;
  }

  .bom .bomTitle .bomTitleRight img {
    width: 120px;
    height: 120px;
  }

  .bom .ba {
    font-size: 0.9rem;
    border-top: 1px solid #646262;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {

  /* 在小于等于 768px 的屏幕宽度下应用的样式 */
  .topHeader {
    position: fixed;
    background-color: #2c3e50;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4.5vh;
    z-index: 99;
    /* display: flex; */
  }

  .box {
    margin-top: 4.5vh;
    height: calc(100% - 4.5vh);
  }

  .conter {
    width: 98vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .conter span {
    font-size: 0.8rem;
    color: azure;
  }

  .topHeader img {
    width: 2rem;
  }

  .topHeader ul {
    display: flex;
    list-style: none;
    height: 100%;
    align-items: center;
    flex: 1;
    justify-content: space-around;
  }

  .topHeader ul li {
    font-size: 0.8rem;
    color: #f1f1f1;
    cursor: pointer;
    text-align: center;
  }

  .topHeader ul li.active {
    color: #4da2ff;
  }

  .bom {
    width: 100vw;
    padding-top: 1rem;
    background: #2c3e50;
    /* height: 35vh; */
  }

  .bom .bomTitle {
    width: 98vw;
    margin: 0 auto;
    box-sizing: border-box;
    padding-bottom: 1rem;
  }

  .bom .bomTitle .bomTitleList {
    display: flex;
    justify-content: space-around;
  }

  .bom .bomTitle .bomTitleList ul {
    list-style: none;
    color: #909399;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .bom .bomTitle .bomTitleList ul li:nth-child(1) {
    color: #fff;
    margin: 0 0 1rem;
    font-size: 0.9rem;
  }

  .bom .bomTitle .bomTitleList ul li {
    margin: 0.2rem 0;
    cursor: pointer;
  }

  .bom .bomTitle .bomTitleRight {
    width: 98vw;
    font-size: 0.8rem;
    font-weight: bold;
    color: #909399;
  }

  .bom .bomTitle .bomTitleRight p {
    display: none;
  }

  .bom .bomTitle .bomTitleRight div {
    margin-top: 1rem;
    display: inline-block;
    width: 50%;
    text-align: center;
  }

  .bom .bomTitle .bomTitleRight img {
    width: 5rem;
    height: 5rem;
  }

  .bom .ba {
    font-size: 0.5rem;
    padding: 0.5rem;
    border-top: 1px solid #646262;
    color: #fff;
    text-align: center;
  }
}
</style>
